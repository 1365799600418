
import Vue from 'vue';
import _ from 'lodash';
import util from '../../../util';

type HiddenCampaign = {
  campaignId: string;
  campaignName: string;
  advertiserId: string;
  advertiserName: string;
};

export default Vue.extend({
  inheritAttrs: false,
  name: 'ArchivedCampaigns',
  props: ['isArchived', 'loading'],
  data: (): {
    search: string;
    pageNumber: number;
    sortDesc: Array<boolean>;
    selectedItemsPer: number;
    itemsPerPage: number;
    isLoading: boolean;
    hiddenCampaigns: HiddenCampaign[];
  } => ({
    search: '',
    pageNumber: 1,
    sortDesc: [false],
    selectedItemsPer: 100,
    itemsPerPage: 10,
    isLoading: false,
    hiddenCampaigns: [],
  }),
  async created() {
    await this.getArchivedCampaigns();
  },
  computed: {
    headers(): Array<{ text: string; align?: string; sortable?: boolean; value: string }> {
      const arr = [
        { text: 'Campaign name', value: 'FriendlyName', sortable: false },
        { text: 'Tactics', value: 'CampaignType', sortable: false },
        { text: 'Start date', value: 'Start_date', sortable: false },
        { text: 'End date', value: 'End_date', sortable: false },
        { text: '', value: 'Actions' },
      ];
      return arr;
    },
    filteredItems() {
      return this.hiddenCampaigns.filter(item => item.FriendlyName.toLowerCase().includes(this.search.toLowerCase()));
    },
    archivedCampaigns() {
      return this.$store.getters.campaigns || {};
    },
  },
  watch: {
    archivedCampaigns(newVal, oldVal) {
      if (!_.isEqual(newVal, oldVal)) {
        this.formatCampaigns();
      }
    },
  },
  methods: {
    formatCampaigns() {
      const campaigns = this.archivedCampaigns;
      if (campaigns && campaigns.Campaign) {
        const formattedCampaigns = campaigns?.Campaign?.map(campaign => {
          const startDate: Date = new Date(campaign.Start_date);
          const endDate: Date = new Date(campaign.End_date);
          const options: object = { year: 'numeric', month: 'short', day: 'numeric' };
          const formattedStartDate = new Intl.DateTimeFormat('en-US', options).format(startDate);
          const formattedEndDate = new Intl.DateTimeFormat('en-US', options).format(endDate);
          const formattedCampaignType = util.tacticTitleMap(campaign.CampaignType)

          return {
            ...campaign,
            CampaignType: formattedCampaignType,
            Start_date: formattedStartDate,
            End_date: formattedEndDate,
            Actions: null,
          };
        });
        this.hiddenCampaigns = formattedCampaigns;
      } else {
        this.hiddenCampaigns = []
      }
    },
    async getArchivedCampaigns(): Promise<void> {
      const advertiserId = this.$route.query.ppid;
      await this.$store.dispatch('getCampaigns', {
        daterange: '',
        advertiserId,
        archiveStatus: 'only_archived',
        skipCached: true,
      });

      this.formatCampaigns();
    },
    async restoreCampaign(campaign): Promise<void> {
      const campaignId = campaign.id;
      const advertiserId = this.$route.query.ppid;

      try {
        const res = await this.$store.dispatch('linearAdmin/unhideCampaign', {
          advertiserId,
          campaignId,
          onSuccessCallback: this.getArchivedCampaigns,
        });
        if (res.error) {
          this.$store.dispatch('showError', {
            message: res.error,
            errorType: 'error',
          });
          return;
        }
        this.$store.dispatch('showError', {
          message: 'Campaign was successfully restore',
          errorType: 'success',
        });
      } catch (error) {
        this.$store.dispatch('showError', {
          message: 'Failed to restore campaign. Please try again.',
          errorType: 'error',
        });
      }
    },
  },
});
